import React, { useEffect, useState } from "react"
import useCommonQueries from "../hooks/useCommonQueries"
import LoadingScreen from "../components/LoadingScreen"
import ErrorScreen from "../components/ErrorScreen"
import VideoPlayer from "../components/VideoPlayer"
import {
  ENTER_FULLSCREEN,
  EXIT_FULLSCREEN,
  FINISH,
  PAUSE,
  SEEK,
  SET_SPEED,
  START,
  STOP,
} from "../enums/UserVideoActions"
import _ from "lodash"
import { devLog, getVideoPlayedRangeData } from "../utils"
import { gql, useMutation, useSubscription } from "@apollo/client"
import {
  LOGIN_REQUIRED,
  PROFILE_NOT_PROVIDED,
} from "../../functions/lib/error-codes"
import { useAuth0 } from "@auth0/auth0-react"
import { useTranslation } from "react-i18next"

const NOW_WATCHINGS_SUBSCRIPTION = gql`
  subscription {
    now_watchinigs {
      session_id
    }
  }
`

const INSERT_NOW_WATCHIING_MUTATION = gql`
  mutation insertNowWatching($sessionId: String!) {
    insert_now_watchinigs_one(
      object: { session_id: $sessionId }
      on_conflict: {
        constraint: now_watchinigs_pkey
        update_columns: session_id
      }
    ) {
      session_id
    }
  }
`

const WatchVideoPage = props => {
  const { t } = useTranslation()
  const { pageContext } = props
  const { video } = pageContext
  const { isAuthenticated, isLoading, error: auth0Error } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [currentSessionId, setCurrentSessionId] = useState(null)
  const [sourceUrl, setSourceUrl] = useState(null)
  const [videoTime, setVideoTime] = useState(0)
  const {
    getSignedVideoSourceUrl,
    logUserVideoAction,
    upsertVideoHistory,
    getVideoHistory,
    insertVideoPlayedRange,
    getCurrentUser,
  } = useCommonQueries()

  const {
    loading: subscriptionLoading,
    data: subscriptionData,
    error: subscriptionError,
  } = useSubscription(NOW_WATCHINGS_SUBSCRIPTION)

  const [insertNowWatching] = useMutation(INSERT_NOW_WATCHIING_MUTATION)

  const initVideo = async () => {
    try {
      const userData = await getCurrentUser()

      devLog({ userData })
      if (!(userData?.basic_profile || userData?.billing_profile)) {
        throw new Error(PROFILE_NOT_PROVIDED)
      }
      const videoSource = await getSignedVideoSourceUrl({ videoId: video.id })
      const time = await getVideoHistory({ videoId: video.id })
      setSourceUrl(videoSource)
      setVideoTime(time)
    } catch (error) {
      devLog({ error })
      if (error.message === "Login required") {
        setError(new Error(LOGIN_REQUIRED))
      } else {
        setError(error)
      }
    }

    setLoading(false)
  }

  const refreshVideoSource = async () => {
    try {
      const videoSource = await getSignedVideoSourceUrl({ videoId: video.id })
      setSourceUrl(videoSource)
    } catch (error) {
      if (error.message === "Login required") {
        setError(new Error(LOGIN_REQUIRED))
      } else {
        setError(error)
      }
    }
  }

  const handleLogUserVideoActionError = error => {
    console.error(error)
  }

  useEffect(() => {
    if (isAuthenticated) {
      initVideo()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (subscriptionData) {
      if (subscriptionData.now_watchinigs?.length >= 1) {
        const nowWatchingSessionId =
          subscriptionData.now_watchinigs[0].session_id
        if (currentSessionId && nowWatchingSessionId !== currentSessionId) {
          setError(new Error(t("error:sessionInterrupted")))
        }
      }
    }
  }, [subscriptionData])

  if (!isLoading && !isAuthenticated) {
    return <ErrorScreen error={new Error(LOGIN_REQUIRED)} />
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const relatedProductList = video.product_rels.map(({ product }) => product)
  devLog({ relatedProductList, productRels: video.product_rels })

  const handleVideoPause = async ({ currentTime, sessionId }) => {
    try {
      await logUserVideoAction({
        videoId: video.id,
        action: PAUSE,
        currentTime,
        sessionId,
      })

      await upsertVideoHistory({ videoId: video.id, time: currentTime })
    } catch (error) {
      handleLogUserVideoActionError(error)
    }
  }

  const handleVideoClose = async ({ currentTime, sessionId }) => {
    try {
      window.history.back()

      await logUserVideoAction({
        videoId: video.id,
        action: STOP,
        currentTime,
        sessionId,
      })

      await upsertVideoHistory({ videoId: video.id, time: currentTime })
    } catch (error) {
      handleLogUserVideoActionError(error)
    }
  }

  const handleVideoPlay = async ({ currentTime, sessionId }) => {
    try {
      setCurrentSessionId(sessionId)
      await insertNowWatching({
        variables: { sessionId },
      })

      await logUserVideoAction({
        videoId: video.id,
        action: START,
        currentTime,
        sessionId,
      })
    } catch (error) {
      handleLogUserVideoActionError(error)
    }
  }

  const handleVideoFinish = async ({ currentTime, sessionId }) => {
    try {
      await logUserVideoAction({
        videoId: video.id,
        action: FINISH,
        currentTime,
        sessionId,
      })

      await upsertVideoHistory({ videoId: video.id, time: currentTime })
    } catch (error) {
      handleLogUserVideoActionError(error)
    }
  }

  const handleVideoSeekChange = async ({
    currentTime,
    previousTime,
    sessionId,
  }) => {
    try {
      await logUserVideoAction({
        videoId: video.id,
        action: SEEK,
        previousTime,
        currentTime,
        sessionId,
      })

      await upsertVideoHistory({ videoId: video.id, time: currentTime })
    } catch (error) {
      handleLogUserVideoActionError(error)
    }
  }

  const handleFullscreenToggle = async ({
    currentTime,
    sessionId,
    fullscreen,
  }) => {
    await logUserVideoAction({
      videoId: video.id,
      action: fullscreen ? ENTER_FULLSCREEN : EXIT_FULLSCREEN,
      currentTime,
      sessionId,
    })
  }

  const handlePlaybackSpeedChange = async ({
    currentTime,
    sessionId,
    playbackSpeed,
  }) => {
    await logUserVideoAction({
      videoId: video.id,
      action: SET_SPEED,
      currentTime,
      sessionId,
      value: `${playbackSpeed}`,
    })
  }

  const onTimeUpdate = _.throttle(
    async ({ currentTime, sessionId, target }) => {
      try {
        if (target) {
          const playedRangeData = getVideoPlayedRangeData({
            sessionId,
            videoId: video.id,
            video: target,
          })

          await insertVideoPlayedRange({ data: playedRangeData, sessionId })
        }

        await upsertVideoHistory({ videoId: video.id, time: currentTime })
      } catch (error) {
        console.log(error)
      }
    },
    5000
  )

  const handleError = error => {
    if ([2, 4].indexOf(error.code) >= 0) {
      refreshVideoSource()
    } else {
      setError(error)
    }
  }

  return (
    <VideoPlayer
      onError={handleError}
      title={video.title}
      startAt={videoTime}
      videoSource={sourceUrl}
      relatedProductList={relatedProductList}
      onClose={handleVideoClose}
      onPause={handleVideoPause}
      onPlay={handleVideoPlay}
      onFinish={handleVideoFinish}
      onSeekChange={handleVideoSeekChange}
      onTimeUpdate={onTimeUpdate}
      onFullscreenToggle={handleFullscreenToggle}
      onPlaybackSpeedChange={handlePlaybackSpeedChange}
    />
  )
}

export default WatchVideoPage
