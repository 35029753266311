import React from "react"
import { Col, List, Row } from "antd"
import { devLog, formatPrice } from "../utils"
import "./RelatedProductList.less"
import { useTranslation } from "react-i18next"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const RelatedProductList = ({ data }) => {
  devLog({ data })

  const { t } = useTranslation()

  const renderItem = (item, index) => {
    if (!item) {
      return null
    }

    const { id, title, picture, price, url } = item

    return (
      <List.Item
        key={id}
        className="related-product-list-item"
        onClick={() => {
          window.open(url, "_blank")
        }}
      >
        <Row gutter={16} className="product-row">
          <Col span={4}>
            <GatsbyImage
              image={getImage(picture?.localFile)}
              alt={title || "cover"}
            />
          </Col>
          <Col span={20} className="product-title-wrapper">
            <h4>{title}</h4>
            <p>{formatPrice(price)}</p>
          </Col>
        </Row>
      </List.Item>
    )
  }
  return (
    <div className="related-product-list-wrapper">
      <h2>{t("label:completeYourFormation")}</h2>
      <hr />
      <List dataSource={data} renderItem={renderItem} />
    </div>
  )
}

export default RelatedProductList
